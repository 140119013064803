<template>
    <div>
        <h3 class="info-list__header">
            {{ service.description }}
        </h3>
        <dt class="info-list__label" v-text="$t('calendar.appointment.rate')" />
        <dd class="info-list__value" v-text="participation.total_amount" />

        <div class="info-list-separator" />
    </div>
</template>

<script>
export default {
    props: {
        participation: {
            type: Object,
            required: true,
        },

        service: {
            type: Object,
            required: true,
        },
    },
};
</script>
