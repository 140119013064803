<template>
    <div class="note-info">
        <frm-panel v-if="loading || !note" :title="$t('calendar.note')">
            <div class="info-list">
                <div v-if="loading" class="info-list__loader">
                    <frm-spinner />
                </div>
                <div v-else v-text="$t('calendar.sidebar.error')" />
            </div>
        </frm-panel>
        <frm-panel v-else :title="$t('calendar.note')" class="note-info__panel">
            <template v-if="noteHasChanged" #header-extra>
                <button type="button"
                        class="panel__header-button"
                        :title="$t('masterfiles.actions.save')"
                        @click.stop="saveNote"
                >
                    <frm-icon name="check" class="panel__header-button-icon" />
                </button>
                <button type="button"
                        class="panel__header-button"
                        :title="$t('masterfiles.actions.cancel')"
                        @click.stop="resetNote"
                >
                    <frm-icon name="cross" class="panel__header-button-icon" />
                </button>
            </template>
            <textarea-autosize v-model="description"
                               class="form-textarea form-textarea--required note-info__note"
                               :min-height="100"
            />
        </frm-panel>
    </div>
</template>

<script>
import jsonApi from '../../json-api-client';
import DialogManager from '../../services/DialogManager';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            description: '',
            note: null,
        };
    },

    computed: {
        noteHasChanged() {
            // Show action buttons if note has been changed
            return this.description !== this.note?.description;
        },
    },

    watch: {
        event: {
            handler(newValue, oldValue) {
                this.fetchNote(newValue.id, !oldValue || newValue.id !== oldValue.id);
            },
            immediate: true,
        },
    },

    methods: {
        async fetchNote(noteId, showLoader = true) {
            this.loading = showLoader;

            try {
                const [note] = await Promise.all([
                    jsonApi.find('calendar_note', noteId).then(response => response.data),
                    // Make sure the loader is shown at least 300 ms to prevent a "Flash Of Loading Content"™
                    new Promise(resolve => setTimeout(() => resolve(), 300)),
                ]);

                this.note = note;
                this.description = this.note.description;
            } catch (errors) {
                await DialogManager.errors(errors);
            } finally {
                this.loading = false;
            }
        },

        async saveNote() {
            try {
                await jsonApi.update('calendar_note', { id: this.event.id, description: this.description });
            } catch (errors) {
                return DialogManager.errors(errors);
            }

            await Promise.all([
                this.fetchNote(this.event.id, false),
                this.$store.dispatch('calendar/fetchEvents'),
            ]);
        },

        resetNote() {
            this.description = this.note.description;
        },
    },
};
</script>

<style lang="scss">
.note-info__panel {
    .panel__content {
        display: flex;
        padding: 0;
    }
}

.note-info__note {
    border-radius: 0;
    flex-grow: 1;
    margin: -1px;
}
</style>
