import de from '@fullcalendar/core/locales/de';
import enGB from '@fullcalendar/core/locales/en-gb';
import es from '@fullcalendar/core/locales/es';
import fr from '@fullcalendar/core/locales/fr';
import nl from '@fullcalendar/core/locales/nl';
import zhCN from '@fullcalendar/core/locales/zh-cn';

export default {
    de: de,
    'en-GB': enGB,
    es: es,
    fr: fr,
    nl: nl,
    'zh-CN': zhCN,
};
