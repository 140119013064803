import moment from 'moment';
import breakpoint from '../../../../services/breakpoint';
import * as CalendarMode from '../../../../enums/CalendarMode';
import * as CalendarFilterMode from '../../../../enums/CalendarFilterMode';

let date = moment();

if (window.frm.state.calendar && typeof (window.frm.state.calendar.date) !== 'undefined') {
    date = moment(window.frm.state.calendar.date, 'YYYY-MM-DD');
}

export default {
    mode: breakpoint('show-full-calendar') ? CalendarMode.WEEK : CalendarMode.DAY,
    events: {},
    schedules: {},
    employees: { data: [], included: [] },
    rooms: { data: [], included: [] },
    devices: { data: [], included: [] },
    locations: { data: [], included: [] },
    activeLocations: [],
    loading: {
        events: false,
        schedules: false,
        locations: false,
        employees: false,
        rooms: false,
        devices: false,
    },
    anonymous: false,
    filter: {
        mode: CalendarFilterMode.EMPLOYEES,
        value: [],
    },
    search: '',
    firstday: 1,
    activeMutation: null,
    openedEvent: null,
    diagnosisCodeEnabled: true,
    ...window.frm.state.calendar || {},
    date: date.toJSON(),
};
