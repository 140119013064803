<template>
    <frm-panel>
        <template #title>
            <div class="form-checkbox">
                <input id="legend-item-all"
                       ref="allCheckbox"
                       :checked="allChecked"
                       :disabled="locations.length === 0"
                       class="form-checkbox__input"
                       type="checkbox"
                       @change="toggleAll"
                >
                <label for="legend-item-all" class="form-checkbox__label">
                    {{ $t('calendar.sidebar.locations') }}
                </label>
            </div>
        </template>
        <p v-if="locations.length === 0" class="calendar-legend__empty" data-testid="calendar-sidebar:locations-empty">
            {{ $t('calendar.sidebar.locations-empty') }}
        </p>
        <ul v-else class="calendar-legend">
            <li v-for="location in locations"
                :key="location.id"
                :style="{ 'border-color': location.color }"
                class="calendar-legend__item"
            >
                <div class="form-checkbox form-checkbox--no-padding">
                    <input :id="'legend-item-'+location.id"
                           :checked="value.includes(location.id)"
                           :value="location.id"
                           class="form-checkbox__input"
                           type="checkbox"
                           @change="updateValue"
                    >
                    <label :for="'legend-item-'+location.id" class="form-checkbox__label">
                        {{ location.description }}
                    </label>
                </div>
            </li>
        </ul>
    </frm-panel>
</template>

<script>
export default {
    props: {
        locations: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    computed: {
        allChecked() {
            return this.locations.every(location => this.value.includes(location.id));
        },
        noneChecked() {
            return this.locations.every(location => !this.value.includes(location.id));
        },
        allIndeterminate() {
            return !this.noneChecked && !this.allChecked;
        },
    },

    watch: {
        allIndeterminate() {
            this.$refs.allCheckbox.indeterminate = this.allIndeterminate;
        },
    },

    methods: {
        updateValue(event) {
            if (event.target.checked) {
                if (!this.value.includes(event.target.value)) {
                    this.$emit('input', [...this.value, event.target.value]);
                }
            } else {
                if (this.value.includes(event.target.value)) {
                    const value = [...this.value];
                    value.splice(this.value.indexOf(event.target.value), 1);
                    this.$emit('input', value);
                }
            }
        },

        toggleAll(event) {
            const locationIds = this.locations.map(location => location.id);

            this.$emit('input', event.target.checked
                ? [...new Set([...this.value, ...locationIds])] // Use a Set to remove duplicates
                : this.value.filter(id => !locationIds.includes(id)));
        },
    },
};
</script>
