<template>
    <div class="form-icon-field">
        <input
            :placeholder="$t('calendar.search.search')"
            :value="value"
            class="form-input form-input--dark form-icon-field__field"
            type="text"
            @input="$emit('input', $event.target.value)"
            @keyup.enter="$emit('submit')"
        >
        <button class="form-icon-field__button" type="button" @click="$emit('input', '')">
            <frm-icon :name="value ? 'cross-2' : 'magnifying-glass'" class="form-icon-field__icon" />
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: '',
            },
        },
    };
</script>
