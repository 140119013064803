<template>
    <!--
        ### INFORMATION ###
        #
        # We have manually overwritten this file because Element UI doesn't support an inline calendar picker. The
        # original file can be found in the datepicker package of Element UI (this file residents from the
        # version 2.4.3). The inline date pickers is to be released in version 3.0 of Element UI, when released
        # this file should be discarded.
        #
        ### INFORMATION ###
    -->
    <div
        v-show="visible"
        :class="[{'has-sidebar': $slots.sidebar || shortcuts}, popperClass]"
        class="el-picker-panel el-picker-panel--inline el-date-picker el-popper"
    >
        <div class="el-picker-panel__body-wrapper">
            <slot name="sidebar" class="el-picker-panel__sidebar" />
            <div v-if="shortcuts" class="el-picker-panel__sidebar">
                <button
                    v-for="(shortcut, index) in shortcuts"
                    :key="index"
                    class="el-picker-panel__shortcut"
                    type="button"
                    @click="handleShortcutClick(shortcut)"
                >
                    {{ shortcut.text }}
                </button>
            </div>
            <div class="el-picker-panel__body">
                <div
                    :class="{ 'el-date-picker__header--bordered': currentView === 'year' || currentView === 'month' }"
                    class="el-date-picker__header"
                >
                    <button
                        :aria-label="t(`el.datepicker.prevYear`)"
                        class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"
                        type="button"
                        @click="prevYear"
                    />
                    <button
                        v-show="currentView === 'date'"
                        :aria-label="t(`el.datepicker.prevMonth`)"
                        class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-arrow-left"
                        type="button"
                        @click="prevMonth"
                    />
                    <span
                        class="el-date-picker__header-label"
                        role="button"
                        @click="showYearPicker"
                    >{{ yearLabel }}</span>
                    <span
                        v-show="currentView === 'date'"
                        :class="{ active: currentView === 'month' }"
                        class="el-date-picker__header-label"
                        role="button"
                        @click="showMonthPicker"
                    >{{ t(`el.datepicker.month${ month + 1 }`) }}</span>
                    <button
                        :aria-label="t(`el.datepicker.nextYear`)"
                        class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"
                        type="button"
                        @click="nextYear"
                    />
                    <button
                        v-show="currentView === 'date'"
                        :aria-label="t(`el.datepicker.nextMonth`)"
                        class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-arrow-right"
                        type="button"
                        @click="nextMonth"
                    />
                </div>

                <div class="el-picker-panel__content">
                    <date-table
                        v-show="currentView === 'date'"
                        :date="date"
                        :default-value="defaultValue ? new Date(defaultValue) : null"
                        :disabled-date="disabledDate"
                        :first-day-of-week="firstDayOfWeek"
                        :selected-date="selectedDate"
                        :selection-mode="selectionMode"
                        :value="new Date(value)"
                        @pick="handleDatePick"
                        @select="handleDateSelect"
                    />
                    <year-table
                        v-show="currentView === 'year'"
                        :date="date"
                        :default-value="defaultValue ? new Date(defaultValue) : null"
                        :disabled-date="disabledDate"
                        :value="new Date(value)"
                        @pick="handleYearPick"
                    />
                    <month-table
                        v-show="currentView === 'month'"
                        :date="date"
                        :default-value="defaultValue ? new Date(defaultValue) : null"
                        :disabled-date="disabledDate"
                        :value="new Date(value)"
                        @pick="handleMonthPick"
                    />
                </div>
            </div>
        </div>

        <div v-show="footerVisible && currentView === 'date'" class="el-picker-panel__footer">
            <el-button
                v-show="selectionMode !== 'dates'"
                class="el-picker-panel__link-btn"
                size="mini"
                type="text"
                @click="changeToNow"
            >
                {{ t('el.datepicker.now') }}
            </el-button>
            <el-button
                class="el-picker-panel__link-btn"
                plain
                size="mini"
                @click="confirm"
            >
                {{ t('el.datepicker.confirm') }}
            </el-button>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        formatDate,
        parseDate,
        getWeekNumber,
        isDate,
        modifyDate,
        modifyTime,
        modifyWithTimeString,
        clearTime,
        prevYear,
        nextYear,
        prevMonth,
        nextMonth,
        changeYearMonthAndClampDate,
        extractDateFormat,
        extractTimeFormat
    } from 'element-ui/packages/date-picker/src/util';
    import Clickoutside from 'element-ui/src/utils/clickoutside';
    import Locale from 'element-ui/src/mixins/locale';
    import ElInput from 'element-ui/packages/input';
    import ElButton from 'element-ui/packages/button';
    import YearTable from 'element-ui/packages/date-picker/src/basic/year-table';
    import MonthTable from 'element-ui/packages/date-picker/src/basic/month-table';
    import DateTable from 'element-ui/packages/date-picker/src/basic/date-table';

    export default {
        mixins: [Locale],

        directives: { Clickoutside },

        watch: {
            value(val) {
                if (this.selectionMode === 'dates' && this.value) return;
                if (isDate(val)) {
                    this.date = new Date(val);
                } else {
                    this.date = this.getDefaultValue();
                }
            },

            defaultValue(val) {
                if (!isDate(this.value)) {
                    this.date = val ? new Date(val) : new Date();
                }
            },

            selectionMode(newVal, oldVal) {
                if (oldVal === 'month') {
                    this.currentView = 'date';
                }

                if (newVal === 'month') {
                    /* istanbul ignore next */
                    if (this.currentView !== 'year' || this.currentView !== 'month') {
                        this.currentView = 'month';
                    }
                } else if (newVal === 'dates') {
                    this.currentView = 'date';
                }
            }
        },

        methods: {
            handleClear() {
                this.date = this.getDefaultValue();
                this.$emit('pick', null);
            },

            emit(value, ...args) {
                if (!value) {
                    this.$emit('pick', value, ...args);
                } else if (Array.isArray(value)) {
                    const dates = value.map(date => clearTime(date));
                    this.$emit('pick', dates, ...args);
                } else {
                    this.$emit('pick', clearTime(value), ...args);
                }
                this.userInputDate = null;
            },

            // resetDate() {
            //   this.date = new Date(this.date);
            // },

            showMonthPicker() {
                this.currentView = 'month';
            },

            showYearPicker() {
                this.currentView = 'year';
            },

            // XXX: 没用到
            // handleLabelClick() {
            //   if (this.currentView === 'date') {
            //     this.showMonthPicker();
            //   } else if (this.currentView === 'month') {
            //     this.showYearPicker();
            //   }
            // },

            prevMonth() {
                this.date = prevMonth(this.date);
            },

            nextMonth() {
                this.date = nextMonth(this.date);
            },

            prevYear() {
                if (this.currentView === 'year') {
                    this.date = prevYear(this.date, 10);
                } else {
                    this.date = prevYear(this.date);
                }
            },

            nextYear() {
                if (this.currentView === 'year') {
                    this.date = nextYear(this.date, 10);
                } else {
                    this.date = nextYear(this.date);
                }
            },

            handleShortcutClick(shortcut) {
                if (shortcut.onClick) {
                    shortcut.onClick(this);
                }
            },

            handleMonthPick(month) {
                if (this.selectionMode === 'month') {
                    this.date = modifyDate(this.date, this.year, month, 1);
                    this.emit(this.date);
                } else {
                    this.date = changeYearMonthAndClampDate(this.date, this.year, month);
                    // TODO: should emit intermediate value ??
                    // this.emit(this.date);
                    this.currentView = 'date';
                }
            },

            handleDateSelect(value) {
                if (this.selectionMode === 'dates') {
                    this.selectedDate = value;
                }
            },

            handleDatePick(value) {
                if (this.selectionMode === 'day') {
                    this.date = this.value
                        ? modifyDate(this.value, value.getFullYear(), value.getMonth(), value.getDate())
                        : modifyWithTimeString(value, this.defaultTime);
                    this.emit(this.date, false);
                } else if (this.selectionMode === 'week') {
                    this.emit(value.date);
                }
            },

            handleYearPick(year) {
                if (this.selectionMode === 'year') {
                    this.date = modifyDate(this.date, year, 0, 1);
                    this.emit(this.date);
                } else {
                    this.date = changeYearMonthAndClampDate(this.date, year, this.month);
                    // TODO: should emit intermediate value ??
                    // this.emit(this.date, true);
                    this.currentView = 'month';
                }
            },

            changeToNow() {
                // NOTE: not a permanent solution
                //       consider disable "now" button in the future
                if (!this.disabledDate || !this.disabledDate(new Date())) {
                    this.date = new Date();
                    this.emit(this.date);
                }
            },

            confirm() {
                if (this.selectionMode === 'dates') {
                    this.emit(this.selectedDate);
                } else {
                    // value were emitted in handle{Date,Time}Pick, nothing to update here
                    // deal with the scenario where: user opens the picker, then confirm without doing anything
                    const value = this.value
                        ? this.value
                        : modifyWithTimeString(this.getDefaultValue(), this.defaultTime);
                    this.date = new Date(value); // refresh date
                    this.emit(value);
                }
            },

            resetView() {
                if (this.selectionMode === 'month') {
                    this.currentView = 'month';
                } else if (this.selectionMode === 'year') {
                    this.currentView = 'year';
                } else {
                    this.currentView = 'date';
                }
            },

            handleEnter() {
                document.body.addEventListener('keydown', this.handleKeydown);
            },

            handleLeave() {
                this.$emit('dodestroy');
                document.body.removeEventListener('keydown', this.handleKeydown);
            },

            handleKeydown(event) {
                const keyCode = event.keyCode;
                const list = [38, 40, 37, 39];
                if (this.visible) {
                    if (list.indexOf(keyCode) !== -1) {
                        this.handleKeyControl(keyCode);
                        event.stopPropagation();
                        event.preventDefault();
                    }
                    if (keyCode === 13 && this.userInputDate === null) { // Enter
                        this.emit(this.date, false);
                    }
                }
            },

            handleKeyControl(keyCode) {
                const mapping = {
                    'year': {
                        38: -4, 40: 4, 37: -1, 39: 1, offset: (date, step) => date.setFullYear(date.getFullYear() + step)
                    },
                    'month': {
                        38: -4, 40: 4, 37: -1, 39: 1, offset: (date, step) => date.setMonth(date.getMonth() + step)
                    },
                    'week': {
                        38: -1, 40: 1, 37: -1, 39: 1, offset: (date, step) => date.setDate(date.getDate() + step * 7)
                    },
                    'day': {
                        38: -7, 40: 7, 37: -1, 39: 1, offset: (date, step) => date.setDate(date.getDate() + step)
                    }
                };
                const mode = this.selectionMode;
                const year = 3.1536e10;
                const now = this.date.getTime();
                const newDate = new Date(this.date.getTime());
                while (Math.abs(now - newDate.getTime()) <= year) {
                    const map = mapping[mode];
                    map.offset(newDate, map[keyCode]);
                    if (typeof this.disabledDate === 'function' && this.disabledDate(newDate)) {
                        continue;
                    }
                    this.date = newDate;
                    this.$emit('pick', newDate, true);
                    break;
                }
            },

            handleVisibleDateChange(value) {
                const date = parseDate(value, this.dateFormat);
                if (date) {
                    if (typeof this.disabledDate === 'function' && this.disabledDate(date)) {
                        return;
                    }
                    this.date = modifyTime(date, this.date.getHours(), this.date.getMinutes(), this.date.getSeconds());
                    this.userInputDate = null;
                    this.resetView();
                    this.emit(this.date, true);
                }
            },

            isValidValue(value) {
                return value && !isNaN(value) && (
                    typeof this.disabledDate === 'function'
                        ? !this.disabledDate(value)
                        : true
                );
            },

            getDefaultValue() {
                // if default-value is set, return it
                // otherwise, return now (the moment this method gets called)
                return this.defaultValue ? new Date(this.defaultValue) : new Date();
            }
        },

        components: {
            YearTable, MonthTable, DateTable, ElInput, ElButton
        },

        data() {
            return {
                popperClass: '',
                date: new Date(),
                value: '',
                defaultValue: null, // use getDefaultValue() for time computation
                defaultTime: null,
                selectionMode: 'week',
                shortcuts: '',
                visible: true,
                currentView: 'date',
                disabledDate: '',
                selectedDate: [],
                firstDayOfWeek: 1,
                showWeekNumber: false,
                format: '',
                arrowControl: false,
                userInputDate: null
            };
        },

        computed: {
            year() {
                return this.date.getFullYear();
            },

            month() {
                return this.date.getMonth();
            },

            week() {
                return getWeekNumber(this.date);
            },

            monthDate() {
                return this.date.getDate();
            },

            footerVisible() {
                return this.selectionMode === 'dates';
            },

            visibleDate() {
                if (this.userInputDate !== null) {
                    return this.userInputDate;
                } else {
                    return formatDate(this.value || this.defaultValue, this.dateFormat);
                }
            },

            yearLabel() {
                const yearTranslation = this.t('el.datepicker.year');
                if (this.currentView === 'year') {
                    const startYear = Math.floor(this.year / 10) * 10;
                    if (yearTranslation) {
                        return startYear + ' ' + yearTranslation + ' - ' + (startYear + 9) + ' ' + yearTranslation;
                    }
                    return startYear + ' - ' + (startYear + 9);
                }
                return this.year + ' ' + yearTranslation;
            },

            timeFormat() {
                if (this.format) {
                    return extractTimeFormat(this.format);
                } else {
                    return 'HH:mm:ss';
                }
            },

            dateFormat() {
                if (this.format) {
                    return extractDateFormat(this.format);
                } else {
                    return 'yyyy-MM-dd';
                }
            }
        }
    };
</script>
