<template>
    <div>
        <filter-select-employees
            v-if="mode === $options.CalendarFilterMode.EMPLOYEES"
            v-model="selected"
            :disabled="disabled"
            :multiple="multiple"
            name="calendar-filter-select-type-employees"
        />
        <filter-select-rooms
            v-if="mode === $options.CalendarFilterMode.ROOMS"
            v-model="selected"
            :disabled="disabled"
            :multiple="multiple"
            name="calendar-filter-select-type-rooms"
        />
        <filter-select-devices
            v-if="mode === $options.CalendarFilterMode.DEVICES"
            v-model="selected"
            :disabled="disabled"
            :multiple="multiple"
            name="calendar-filter-select-type-devices"
        />
    </div>
</template>

<script>
    import FilterSelectEmployees from './FilterSelect/Employees';
    import FilterSelectDevices from './FilterSelect/Devices';
    import FilterSelectRooms from './FilterSelect/Rooms';
    import * as CalendarFilterMode from '../../enums/CalendarFilterMode';

    export default {
        components: {
            'filter-select-employees': FilterSelectEmployees,
            'filter-select-devices': FilterSelectDevices,
            'filter-select-rooms': FilterSelectRooms,
        },

        props: {
            mode: {
                type: String,
                required: true,
            },
            value: {
                type: Array,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            selected: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value.sort());
                },
            },
        },

        CalendarFilterMode,
    };
</script>
