<template>
    <frm-dropdown
        ref="dropdown"
        :disabled="disabled"
        :icon="currentMode.icon"
        :label="currentMode.label"
    >
        <ul class="dropdown__list">
            <li v-for="mode in modes" :key="mode.type" class="dropdown__item">
                <a href="" class="dropdown__link" @click.prevent="setMode(mode.type)">
                    <frm-icon :name="mode.icon" class="dropdown__link-icon" />
                    {{ mode.label }}
                </a>
            </li>
        </ul>
    </frm-dropdown>
</template>

<script>
    import * as CalendarFilterMode from '../../enums/CalendarFilterMode';

    export default {
        props: {
            value: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                opened: false,

                modes: [
                    {
                        type: CalendarFilterMode.EMPLOYEES,
                        icon: 'employee',
                        label: this.$tc('calendar.filter-employees', 1),
                    },
                    {
                        type: CalendarFilterMode.ROOMS,
                        icon: 'room',
                        label: this.$tc('calendar.filter-rooms', 1),
                    },
                    {
                        type: CalendarFilterMode.DEVICES,
                        icon: 'device',
                        label: this.$tc('calendar.filter-devices', 1),
                    },
                ],
            };
        },

        computed: {
            currentMode() {
                return this.modes.find(mode => mode.type === this.value);
            },
        },

        methods: {
            setMode(mode) {
                this.$emit('input', mode);
                this.$refs.dropdown.opened = false;
            },
        },
    };
</script>
