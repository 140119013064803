<template>
    <div>
        <h3 class="info-list__header">
            <template v-if="participation.subscription_category">
                {{ participation.subscription_category }}
            </template>
            <template v-else>
                {{ $t('calendar.appointment.subscription-not-set') }}
            </template>
        </h3>

        <template v-if="participation.description">
            <dt class="info-list__label" v-text="$t('calendar.appointment.subscription')" />
            <dd class="info-list__value" v-text="participation.description" />

            <dt class="info-list__label" v-text="$t('calendar.appointment.subscription-start-date')" />
            <dd class="info-list__value" v-text="$ds(participation.subscription_item_data_start, 'date')" />

            <dt class="info-list__label" v-text="$t('calendar.appointment.subscription-end-date')" />
            <dd class="info-list__value">
                <template v-if="participation.subscription_item_data_end">
                    {{ $ds(participation.subscription_item_data_end, 'date') }}
                </template>
                <template v-else>
                    {{ $t('patient.terms.ongoing') }}
                </template>
            </dd>

            <dt class="info-list__label" v-text="$t('calendar.appointment.invoice-method')" />
            <dd class="info-list__value"
                v-text="$t(`masterfiles.values.invoice_method-${participation.invoice_method}`)"
            />
        </template>

        <div class="info-list-separator" />

        <template v-if="participation.subscription_usage">
            <dt class="info-list__label info-list__label--wide"
                v-text="$t('calendar.appointment.reimbursement-total')"
            />
            <dd class="info-list__value info-list__value--wide info-list__value--right"
                v-text="$t(unit, { amount: participation.subscription_usage.allowed_usage })"
            />

            <dt class="info-list__label info-list__label--wide"
                v-text="$t('calendar.appointment.reimbursement-up-to-and-including-this')"
            />
            <dd class="info-list__value info-list__value--wide info-list__value--right"
                v-text="$t(unit, { amount: participation.subscription_usage.used_units })"
            />

            <dt class="info-list__label info-list__label--wide"
                v-text="$t('calendar.appointment.reimbursement-planned')"
            />
            <dd class="info-list__value info-list__value--wide info-list__value--right"
                v-text="$t(unit, { amount: participation.subscription_usage.planned_units })"
            />

            <dt class="info-list__label info-list__label--wide"
                v-text="$t('calendar.appointment.reimbursement-left')"
            />
            <dd class="info-list__value info-list__value--wide info-list__value--right"
                v-text="$t(unit, { amount: participation.subscription_usage.remaining_units })"
            />
            <div class="info-list-separator" />
        </template>
    </div>
</template>

<script>
export default {
    props: {
        participation: {
            type: Object,
            required: true,
        },
    },
    computed: {
        unit() {
            return `patient.fields.policies.${this.participation.invoice_method === 'prepaid_number' ? 'unit-treatments' : 'unit-minutes'}`;
        },
    },
};
</script>
