import moment from 'moment';
import dateParser from '../../../../i18n/dateParser';
import dateFormatter from '../../../../i18n/dateFormatter';
import * as CalendarItemViewType from '../../../../enums/CalendarItemViewType';
import * as CalendarFilterMode from '../../../../enums/CalendarFilterMode';
import * as CalendarMode from '../../../../enums/CalendarMode';
import MasterFinancialStatusColors from '../../../../enums/MasterFinancialStatusColors';
import CalendarNote from '../../../../definitions/CalendarNote';
import CalendarItem from '../../../../definitions/CalendarItem';

export const date = (state) => {
    return moment(state.date);
};

export const hashForEvent = () => (event, type) => {
    return `${type || event.type}|${event.id}|${event.start || event.date}`;
};

export const modeForFullCalendar = (state, getters) => {
    if (state.mode === CalendarMode.DAY) {
        return getters.showResources ? 'resourceTimeGridDay' : state.mode;
    }

    return state.mode;
};

export const events = (state, getters) => {
    return Object.values(state.events)
        .filter(event => event.type !== CalendarNote.name)
        .map((event) => {
            const eventType = CalendarItemViewType.APPOINTMENT;
            const locationIsActive = state.activeLocations.includes(String(event.location_id));

            const dotColor = event.invoicing_status ? MasterFinancialStatusColors[event.invoicing_status] : null;
            const classNames = [
                'calendar-event',
                'calendar-event--type-' + eventType,
            ];

            if (state.openedEvent === event.hash) {
                classNames.push('calendar-event--opened');
            } else if (getters.openedEvent) {
                classNames.push('calendar-event--not-opened');
            }

            if (!locationIsActive) {
                classNames.push('calendar-event--inactive');
            }

            if (event.has_note) {
                classNames.push('calendar-event--has-note');
            }

            if (state.activeMutation && state.activeMutation.id === event.id) {
                classNames.push(`calendar-event--mutation-${state.activeMutation.type}`);
            }

            let resourceIds = [];
            switch (state.filter.mode) {
                case CalendarFilterMode.EMPLOYEES:
                    resourceIds = event.employees.map(String);
                    break;
                case CalendarFilterMode.ROOMS:
                    resourceIds = event.location_rooms.map(String);
                    break;
                case CalendarFilterMode.DEVICES:
                    resourceIds = event.location_devices.map(String);
                    break;
            }

            return {
                id: event.hash,
                title: event.description || '',
                start: event.start,
                end: event.end,
                editable: Boolean(event.is_editable),
                backgroundColor: event.color,
                borderColor: event.color_location,
                allDay: false,
                className: classNames.join(' '),
                resourceIds: resourceIds,
                extendedProps: {
                    apiId: event.id,
                    hash: event.hash,
                    eventType,
                    dotColor,
                    displayEventTime: true, // Not officially supported per event, so we mimic it ourselves
                    displayEventEnd: state.mode === CalendarMode.MONTH, // Not officially supported per event, so we mimic it ourselves
                    locationId: event.location_id,
                    hasNote: event.has_note,
                },
            };
        });
};

export const notes = (state) => {
    return Object.values(state.events)
        .filter(event => event.type === CalendarNote.name)
        .map(event => ({
            id: event.hash,
            title: event.description.includes('\n') ? `${event.description.split('\n')[0]}...` : event.description,
            start: event.date,
            editable: false,
            allDay: true,
            className: 'calendar-note',
            resourceIds: event.employees.map(String),
            extendedProps: {
                apiId: event.id,
                hash: event.hash,
                eventType: CalendarItemViewType.NOTE,
                displayEventTime: false,
                displayEventEnd: false,
            },
        }));
};

function splitScheduleIntoPlaceholders(schedule) {
    if (!schedule.interval || schedule.interval < 5) {
        return [];
    }

    const scheduleStart = moment(dateParser(schedule.start));
    const scheduleEnd = moment(dateParser(schedule.end));
    const interval = Number(schedule.interval);
    const placeholders = [];
    let iteration = 0;

    while (true) {
        const placeholder = { ...schedule };
        const placeholderStart = scheduleStart.clone().add(iteration * interval, 'minutes');
        const placeholderEnd = placeholderStart.clone().add(interval, 'minutes');

        if (placeholderEnd > scheduleEnd) {
            break;
        }

        placeholder.start = dateFormatter(placeholderStart);
        placeholder.end = dateFormatter(placeholderEnd);
        placeholder.id = `${placeholder.id}_${iteration}|${placeholder.start}`;

        placeholders.push(placeholder);

        iteration++;
    }

    return placeholders;
}

function placeholderOverlapsEvent(placeholder, event) {
    // if placeholder and event don't share any resource they cannot overlap
    if (placeholder.resourceIds.every(resource => !event.resourceIds.includes(resource))) {
        return false;
    }

    return datesOverlap(placeholder.start, placeholder.end, event.start, event.end);
}

/**
 * @param {String|moment.Moment} startA
 * @param {String|moment.Moment} endA
 * @param {String|moment.Moment} startB
 * @param {String|moment.Moment} endB
 *
 * @returns {Boolean}
 */
function datesOverlap(startA, endA, startB, endB) {
    startA = startA instanceof moment ? startA.clone().local() : moment(dateParser(startA));
    endA = endA instanceof moment ? endA.clone().local() : moment(dateParser(endA));
    startB = startB instanceof moment ? startB.clone().local() : moment(dateParser(startB));
    endB = endB instanceof moment ? endB.clone().local() : moment(dateParser(endB));

    return startA.isBefore(endB) && endA.isAfter(startB);
}

export const placeholders = (state, getters) => {
    if (state.mode === CalendarMode.MONTH) {
        return [];
    }

    return Object.values(state.schedules).flatMap(schedule => {
        return splitScheduleIntoPlaceholders(schedule)
            .map(placeholder => {
                let eventType = null;
                let resourceIds = [];
                switch (state.filter.mode) {
                    case CalendarFilterMode.EMPLOYEES:
                        eventType = CalendarItemViewType.EMPLOYEE_SCHEDULE_PLACEHOLDER;
                        resourceIds = [String(schedule.employee_id)];
                        break;
                    case CalendarFilterMode.ROOMS:
                        eventType = CalendarItemViewType.LOCATION_ROOM_SCHEDULE_PLACEHOLDER;
                        resourceIds = [String(schedule.room_id)];
                        break;
                    case CalendarFilterMode.DEVICES:
                        eventType = CalendarItemViewType.LOCATION_DEVICE_SCHEDULE_PLACEHOLDER;
                        resourceIds = [String(schedule.device_id)];
                        break;
                }
                const locationIsActive = state.activeLocations.includes(String(placeholder.location_id));

                const classNames = [
                    'calendar-event',
                    'calendar-event--type-' + eventType,
                ];

                if (!locationIsActive) {
                    classNames.push('calendar-event--inactive');
                }

                return {
                    id: placeholder.id,
                    title: placeholder.description || '',
                    start: placeholder.start,
                    end: placeholder.end,
                    editable: false,
                    backgroundColor: '#fff',
                    borderColor: placeholder.color_location,
                    allDay: false,
                    className: classNames.join(' '),
                    resourceIds: resourceIds,
                    extendedProps: {
                        apiId: placeholder.id,
                        hash: placeholder.id,
                        eventType,
                        displayEventTime: true, // Not officially supported per event, so we mimic it ourselves
                        displayEventEnd: false, // Not officially supported per event, so we mimic it ourselves
                        employees: placeholder.employee_id ? [placeholder.employee_id] : [],
                        locationId: placeholder.location_id,
                        locationDevices: placeholder.device_id ? [placeholder.device_id] : [],
                        locationRooms: placeholder.room_id ? [placeholder.room_id] : [],
                    },
                };
            })
            .filter(placeholder => {
                return Object.values(getters.events).every(event => !placeholderOverlapsEvent(placeholder, event));
            });
    });
};

export const nonBusiness = (state, getters) => {
    if (state.mode === CalendarMode.MONTH) {
        return [];
    }

    const schedulesGrouped = Object.values(state.schedules).reduce(
        (acc, schedule) => {
            switch (state.filter.mode) {
                case CalendarFilterMode.EMPLOYEES:
                    (acc[schedule.employee_id] = acc[schedule.employee_id] || []).push(schedule);
                    break;
                case CalendarFilterMode.ROOMS:
                    (acc[schedule.room_id] = acc[schedule.room_id] || []).push(schedule);
                    break;
                case CalendarFilterMode.DEVICES:
                    (acc[schedule.device_id] = acc[schedule.device_id] || []).push(schedule);
                    break;
            }

            return acc;
        },
        state.filter.value.reduce((acc, key) => ({ ...acc, [key]: [] }), {})
    );

    return Object.keys(schedulesGrouped).flatMap(key => {
        if (schedulesGrouped[key].length === 0) {
            return [
                {
                    groupId: key,
                    start: getters.date.format('YYYY-MM-DDT00:00:00'),
                    end: getters.date.format('YYYY-MM-DDT00:00:00'),
                    display: 'inverse-background',
                    allDay: false,
                    className: 'fc-non-business',
                    resourceIds: getters.showResources ? [key] : [],
                },
            ];
        }

        return schedulesGrouped[key].map(schedule => {
            return {
                groupId: key,
                start: schedule.start,
                end: schedule.end,
                display: 'inverse-background',
                allDay: false,
                className: 'fc-non-business',
                resourceIds: getters.showResources ? [key] : [],
            };
        });
    });
};

export const business = (state, getters) => {
    return Object.values(state.schedules).map((schedule) => {
        let eventType = null;
        let resourceIds = [];
        switch (state.filter.mode) {
            case CalendarFilterMode.EMPLOYEES:
                eventType = CalendarItemViewType.EMPLOYEE_SCHEDULE_BACKGROUND;
                resourceIds = [String(schedule.employee_id)];
                break;
            case CalendarFilterMode.ROOMS:
                eventType = CalendarItemViewType.LOCATION_ROOM_SCHEDULE_BACKGROUND;
                resourceIds = [String(schedule.room_id)];
                break;
            case CalendarFilterMode.DEVICES:
                eventType = CalendarItemViewType.LOCATION_DEVICE_SCHEDULE_BACKGROUND;
                resourceIds = [String(schedule.device_id)];
                break;
        }

        return {
            id: `${schedule.id}|background`,
            start: schedule.start,
            end: schedule.end,
            display: 'background',
            className: 'fc-business ' + (schedule.room_id ? 'fc-business--room' : ''),
            backgroundColor: schedule.room_id ? getters.roomById(schedule.room_id)?.color : null,
            resourceIds: getters.showResources ? resourceIds : [],
            extendedProps: {
                hash: `${schedule.id}|background`,
                eventType,
                employees: schedule.employee_id ? [schedule.employee_id] : [],
                locationId: schedule.location_id,
                locationDevices: schedule.device_id ? [schedule.device_id] : [],
                locationRooms: schedule.room_id ? [schedule.room_id] : [],
            },
        };
    });
};

export const eventsForFullCalendar = (state, getters) => {
    return [
        ...getters.notes,
        ...getters.events,
        ...getters.placeholders,
        ...getters.business,
        ...getters.nonBusiness,
    ];
};

export const showResources = (state) => {
    return state.mode === CalendarMode.DAY && state.filter.value.length > 1;
};

function employeeHasSchedule(employeeId, schedules) {
    return Object.values(schedules).some(schedule => schedule.employee_id === employeeId);
}

function roomHasSchedule(roomId, schedules) {
    return Object.values(schedules).some(schedule => schedule.room_id === roomId);
}

function resourceIdInEvents(resourceId, events) {
    return events.some(event => event.resourceIds.includes(resourceId));
}

export const resourcesForFullCalendar = (state, getters) => {
    if (!getters.showResources) {
        return [];
    }

    switch (state.filter.mode) {
        case CalendarFilterMode.EMPLOYEES:
            return getters.employees
                .filter(employee => state.filter.value.includes(employee.id))
                .filter(employee => resourceIdInEvents(employee.id, [...getters.events, ...getters.notes]) || employeeHasSchedule(employee.id, state.schedules))
                .map(employee => ({ id: employee.id, title: employee.name.fullname }));
        case CalendarFilterMode.ROOMS:
            return getters.rooms
                .filter(room => state.filter.value.includes(room.id))
                .filter(room => resourceIdInEvents(room.id, [...getters.events, ...getters.notes]) || roomHasSchedule(room.id, state.schedules))
                .map(room => ({ id: room.id, title: room.description }));
        case CalendarFilterMode.DEVICES:
            return getters.devices
                .filter(device => state.filter.value.includes(device.id))
                .filter(device => resourceIdInEvents(device.id, [...getters.events, ...getters.notes]))
                .map(device => ({ id: device.id, title: device.description }));
    }

    return [];
};

export const locations = (state) => {
    // We only add the location to the sidebar if we can find a location in the events
    return state.locations.data.filter(location => {
        return Object.values(state.events).some(event => String(event.location_id) === String(location.id)) ||
            Object.values(state.schedules).some(schedule => String(schedule.location_id) === String(location.id));
    });
};

export const locationById = (state, getters) => (locationId) => {
    return state.locations.data.find(location => location.id === String(locationId));
};

export const employees = (state) => {
    if (!state.employees.data) {
        return [];
    }
    return state.employees.data.filter(employee => employee.active);
};

export const employeeById = (state) => (employeeId) => {
    return state.employees.data.find(employee => employee.id === String(employeeId));
};

export const rooms = (state) => {
    if (!state.rooms.data) {
        return [];
    }
    return state.rooms.data.filter(room => room.active);
};

export const roomById = (state) => (roomId) => {
    return state.rooms.data.find(room => room.id === String(roomId));
};

export const devices = (state) => {
    if (!state.devices.data) {
        return [];
    }
    return state.devices.data.filter(device => device.active);
};

export const deviceById = (state) => (deviceId) => {
    return state.devices.data.find(device => device.id === String(deviceId));
};

export const selectionMode = (state) => {
    switch (state.mode) {
        case CalendarMode.MONTH:
            return 'month';
        case CalendarMode.DAY:
            return 'day';
        case CalendarMode.DAY_PLUS_SEVEN:
            return 'day';
        default:
            return 'week';
    }
};

export const getEventByHash = (state, getters) => (hash) => {
    return getters.eventsForFullCalendar.find(event => event.extendedProps && event.extendedProps.hash === hash);
};

export const startDate = (state, getters) => {
    switch (state.mode) {
        case CalendarMode.DAY:
            return getters.date.clone();
        case CalendarMode.WEEK:
        case CalendarMode.WORKWEEK:
            return getters.date.clone().startOf('week');
        case CalendarMode.MONTH:
            return getters.date.clone().startOf('month').startOf('week');
        case CalendarMode.DAY_PLUS_SEVEN:
            return getters.date.clone();
        default:
            throw new Error(`Mode ${state.mode} not yet implemented`);
    }
};

export const endDate = (state, getters) => {
    switch (state.mode) {
        case CalendarMode.DAY:
            return getters.date.clone();
        case CalendarMode.WEEK:
            return getters.date.clone().endOf('week');
        case CalendarMode.WORKWEEK:
            return getters.date.clone().startOf('week').add(4, 'days');
        case CalendarMode.MONTH:
            return getters.date.clone().startOf('month').startOf('week').add(5, 'weeks').endOf('week');
        case CalendarMode.DAY_PLUS_SEVEN:
            return getters.date.clone().add(7, 'weeks');
        default:
            throw new Error(`Mode ${state.mode} not yet implemented`);
    }
};

export const showCalendarNotes = (state) => {
    return state.filter.mode === CalendarFilterMode.EMPLOYEES;
};

export const loading = (state) => {
    return Object.values(state.loading).some(Boolean);
};

export const openedEvent = (state, getters) => {
    if (state.openedEvent === null || !state.events[state.openedEvent]) {
        return null;
    }

    return state.events[state.openedEvent];
};

export const openedAppointmentEvent = (state, getters) => {
    const event = getters.openedEvent;

    if (!event || event.type !== CalendarItem.name) {
        return null;
    }

    return {
        ...event,
        employees: event.employees.map(employeeId => getters.employeeById(employeeId)),
        location: getters.locationById(event.location_id),
        location_devices: event.location_devices?.map(deviceId => getters.deviceById(deviceId)),
        location_rooms: event.location_rooms?.map(roomId => getters.roomById(roomId)),
    };
};

export const openedNoteEvent = (state, getters) => {
    const event = getters.openedEvent;

    if (!event || event.type !== CalendarNote.name) {
        return null;
    }

    return event;
};

export const findScheduleByEmployeeAndDate = (state) => (employeeId, start, end) => {
    return Object.values(state.schedules)
        .find(schedule => schedule.employee_id === employeeId &&
            dateParser(start) >= dateParser(schedule.start) && dateParser(end) <= dateParser(schedule.end));
};

export const filterValueLabels = (state, getters) => {
    return state.filter.value.map(value => {
        switch (state.filter.mode) {
            case CalendarFilterMode.EMPLOYEES:
                return getters.employeeById(value)?.name?.fullname;
            case CalendarFilterMode.ROOMS:
                return getters.roomById(value)?.description;
            case CalendarFilterMode.DEVICES:
                return getters.deviceById(value)?.description;
        }
    }).filter(Boolean);
};
