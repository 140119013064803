import state from './state';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';
import { createPersistedState as createPersistedStatePlugin } from '../../../helpers';

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};

/**
 * @param {string} namespace
 * @param {number} [ttl]
 *
 * @returns {function(store: any): void}
 */
export function createPersistedState(namespace, ttl = 6 * 60 * 60 * 1000) {
    return createPersistedStatePlugin({
        key: `vuex-${namespace}`,
        ttl,
        paths: [
            `${namespace}.anonymous`,
            `${namespace}.date`,
            `${namespace}.filter`,
            `${namespace}.mode`,
            `${namespace}.employees`,
            `${namespace}.locations`,
            `${namespace}.rooms`,
            `${namespace}.devices`,
            `${namespace}.search`,
        ],
        jsonApiPaths: {
            [`${namespace}.employees`]: 'employee',
            [`${namespace}.locations`]: 'location',
            [`${namespace}.rooms`]: 'location_room',
            [`${namespace}.devices`]: 'location_device',
        },
    });
}
