export const EMPLOYEE_SCHEDULE = 'employee_schedule';

export const EMPLOYEE_SCHEDULE_BACKGROUND = 'employee_schedule_background';

export const EMPLOYEE_SCHEDULE_PLACEHOLDER = 'employee_schedule_placeholder';

export const LOCATION_DEVICE_SCHEDULE = 'location_device_schedule';

export const LOCATION_DEVICE_SCHEDULE_BACKGROUND = 'location_device_schedule_background';

export const LOCATION_DEVICE_SCHEDULE_PLACEHOLDER = 'location_device_schedule_placeholder';

export const LOCATION_ROOM_SCHEDULE = 'location_room_schedule';

export const LOCATION_ROOM_SCHEDULE_BACKGROUND = 'location_room_schedule_background';

export const LOCATION_ROOM_SCHEDULE_PLACEHOLDER = 'location_room_schedule_placeholder';

export const RESERVATION = 'reservation';

export const APPOINTMENT = 'appointment';

export const SLOT = 'slot';

export const NOTE = 'note';

export const BLOCKED = 'blocked';
