// @vue/component

import * as CalendarItemOption from '../../enums/CalendarItemOption';
import CalendarItemWithPerformanceCode from '../../definitions/CalendarItemWithPerformanceCode';
import AccessRights from '../../services/AccessRights';
import * as AccessRight from '../../enums/AccessRight';
import CalendarItemWithService from '../../definitions/CalendarItemWithService';
import CalendarItemWithSubscription from '../../definitions/CalendarItemWithSubscription';
import buildContextMenuItemsFromActions from '../../services/buildContextMenuItemsFromActions';
import AssertsEventType from './AssertsEventType';
import store from '../../store';
import i18n from '../../i18n';
import * as CalendarMode from '../../enums/CalendarMode';

const createItemsForBackgroundOrPlaceholder = (event, contextClickInfo, actionCallback) => {
    return buildContextMenuItemsFromActions([{
        name: CalendarItemOption.APPOINTMENT,
        label: i18n.t('calendar.context-menu.appointment'),
        icon: 'appointment-subject',
    }, store.state.calendar.mode === CalendarMode.MONTH // Add note option only in month view
        ? {
            name: CalendarItemOption.NOTE,
            label: i18n.t('calendar.context-menu.note'),
            icon: 'note',
        } : null,
    { type: 'break' }, {
        name: CalendarItemOption.PASTE,
        label: i18n.t('calendar.context-menu.paste'),
        icon: 'general-paste',
        disabled: store.state.calendar.activeMutation === null || !(Boolean(event) || contextClickInfo),
    },
    ].filter(item => !!item), actionCallback);
};

export default {
    mixins: [AssertsEventType],

    methods: {
        async createContextMenuItemsForEvent(event) {
            let contextItems = [];
            const eventData = await this.fetchEvent(event.extendedProps.apiId);
            const isGroupAppointment = eventData.calendar_item_participations?.length > 1 || false;

            contextItems.push({
                name: CalendarItemOption.OPEN,
                label: this.$t('calendar.context-menu.open'),
                icon: 'appointment-subject',
                disabled: !event || !event.editable,
            });

            if (isGroupAppointment) {
                contextItems.push({
                    name: CalendarItemOption.OPEN_GROUP,
                    label: this.$t('calendar.context-menu.open-group'),
                    icon: 'group',
                    disabled: !event || !event.editable,
                });
            } else {
                // Not a groupAppointment
                // Actions specifically for CalendarItemWithPerformanceCode
                if (eventData.type === CalendarItemWithPerformanceCode.name) {
                    if (AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_CONTACT_MOMENTS)) {
                        contextItems.push({
                            name: CalendarItemOption.EDIT_CONTACT_MOMENT,
                            label: this.$t('calendar.context-menu.edit-contact-moment'),
                            icon: 'contact-moment',
                        });
                    }

                    const groupedContextItems = [];
                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_GENERAL)) {
                        groupedContextItems.push({
                            name: CalendarItemOption.EDIT_PATIENT,
                            label: this.$t('calendar.context-menu.edit-patient'),
                            icon: 'patient',
                        });
                        groupedContextItems.push({
                            name: CalendarItemOption.EDIT_PATIENT_POLICY,
                            label: this.$t('calendar.context-menu.edit-patient-policy'),
                            icon: 'insurer',
                        });
                        groupedContextItems.push({
                            name: CalendarItemOption.SHOW_PATIENT_POLICY,
                            label: this.$t('calendar.context-menu.show-patient-policy'),
                            icon: 'insurer',
                        });
                        groupedContextItems.push({
                            name: CalendarItemOption.COV,
                            label: this.$t('calendar.context-menu.cov'),
                            icon: 'patient',
                        });
                    }
                    if (AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_INDICATION_GENERAL) || AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_INDICATION_FINANCIAL)) {
                        groupedContextItems.push({
                            name: CalendarItemOption.EDIT_INDICATION_HISTORY,
                            label: this.$t('calendar.context-menu.edit-indication-history'),
                            icon: 'indication',
                        });
                    }
                    if (AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP)) {
                        groupedContextItems.push({
                            name: CalendarItemOption.OPEN_FYSIOROADMAP,
                            label: this.$t('calendar.context-menu.open-fysioroadmap'),
                            icon: 'fysioroadmap',
                        });
                    }
                    groupedContextItems.push({
                        name: CalendarItemOption.UNFULFILLED,
                        label: this.$t('calendar.context-menu.unfulfilled'),
                        icon: 'unfulfilled',
                        disabled: !eventData.is_unfulfillable,
                    });
                    if (AccessRights.hasAccessTo(AccessRight.DECLARATIONS)) {
                        groupedContextItems.push({
                            name: CalendarItemOption.OPEN_PAYMENT_STATUS,
                            label: this.$t('calendar.context-menu.open-payment-status'),
                            icon: 'masterfiles-financial',
                        });
                    }
                    if (groupedContextItems.length) {
                        contextItems.push({ type: 'break' });
                        contextItems = contextItems.concat(groupedContextItems);
                    }
                }

                if (eventData.type === CalendarItemWithService.name && AccessRights.hasAccessTo(AccessRight.PATIENT_GENERAL)) {
                    contextItems.push({
                        name: CalendarItemOption.EDIT_PATIENT,
                        label: this.$t('calendar.context-menu.edit-patient'),
                        icon: 'patient',
                    });
                } else if (eventData.type === CalendarItemWithSubscription.name) {
                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_GENERAL)) {
                        contextItems.push({
                            name: CalendarItemOption.EDIT_PATIENT,
                            label: this.$t('calendar.context-menu.edit-patient'),
                            icon: 'patient',
                        });
                    }

                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_SUBSCRIPTIONS)) {
                        contextItems.push({
                            name: CalendarItemOption.EDIT_PATIENT_SUBSCRIPTION,
                            label: this.$t('calendar.context-menu.edit-patient-subscription'),
                            icon: 'subscription',
                        });
                    }
                }
            }

            contextItems.push({ type: 'break' });

            contextItems.push({
                name: CalendarItemOption.CUT,
                label: this.$t('calendar.context-menu.cut'),
                icon: 'general-cut',
                disabled: !event || !event.editable,
            });
            contextItems.push({
                name: CalendarItemOption.COPY,
                label: this.$t('calendar.context-menu.copy'),
                icon: 'general-copy',
            });

            contextItems.push({ type: 'break' });
            contextItems.push({
                name: CalendarItemOption.DELETE,
                label: this.$t('calendar.context-menu.delete'),
                icon: 'general-delete',
                disabled: !event || !event.editable,
            });

            if (eventData.has_recurring_appointments) {
                contextItems.push({
                    name: CalendarItemOption.DELETE_RECURRENCES,
                    label: this.$t('calendar.context-menu.delete-recurrences'),
                    icon: 'general-delete',
                });
            }

            const eventHasExactlyOneParticipation = eventData.calendar_item_participations &&
                eventData.calendar_item_participations.length === 1;
            const indicationHistoryHasModule = eventHasExactlyOneParticipation &&
                eventData.calendar_item_participations[0].indication_history?.module;
            const firstParticipationHasSubscriptionItemData = eventHasExactlyOneParticipation &&
                eventData.calendar_item_participations[0].subscription_item_data_id;

            if (!eventHasExactlyOneParticipation) {
                (contextItems.find(item => item.name === CalendarItemOption.EDIT_PATIENT) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.EDIT_PATIENT_POLICY) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.SHOW_PATIENT_POLICY) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.COV) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.OPEN_FYSIOROADMAP) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.UNFULFILLED) || {}).disabled = true;
            }
            if (!indicationHistoryHasModule) {
                (contextItems.find(item => item.name === CalendarItemOption.EDIT_CONTACT_MOMENT) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.EDIT_INDICATION_HISTORY) || {}).disabled = true;
                (contextItems.find(item => item.name === CalendarItemOption.OPEN_PAYMENT_STATUS) || {}).disabled = true;
            }

            if (!firstParticipationHasSubscriptionItemData) {
                (contextItems.find(item => item.name === CalendarItemOption.EDIT_PATIENT_SUBSCRIPTION) || {}).disabled = true;
            }

            return buildContextMenuItemsFromActions(contextItems, this.createActionCallback(event, eventData));
        },

        async createContextMenuItemsForNote(event) {
            return buildContextMenuItemsFromActions([
                {
                    name: CalendarItemOption.EDIT_NOTE,
                    label: i18n.t('calendar.context-menu.edit-note'),
                    icon: 'note',
                },
                {
                    name: CalendarItemOption.DELETE_NOTE,
                    label: i18n.t('calendar.context-menu.delete-note'),
                    icon: 'general-delete',
                },
            ], this.createActionCallback(event));
        },

        async createContextMenuItemsForPlaceholder(event, contextClickInfo) {
            return createItemsForBackgroundOrPlaceholder(event, contextClickInfo, this.createActionCallback(event));
        },

        async createContextMenuItemsForEventsBackground(event, contextClickInfo) {
            return createItemsForBackgroundOrPlaceholder(event, contextClickInfo, this.createActionCallback(event));
        },

        async createContextMenuItemsForNoteBackground(event) {
            return buildContextMenuItemsFromActions([
                {
                    name: CalendarItemOption.NOTE,
                    label: i18n.t('calendar.context-menu.note'),
                    icon: 'note',
                },
            ], this.createActionCallback(event));
        },

        createActionCallback(event, eventData) {
            return (action) => {
                this['action-' + action.name](event, eventData);
            };
        },
    },
};
