import * as MasterFinancialStatus from './MasterFinancialStatus';

export default {
    [MasterFinancialStatus.APPROVED]: '#61a058',
    [MasterFinancialStatus.APPROVED_CREDIT_TO_INSURER]: '#ffe380',
    [MasterFinancialStatus.COMPLETE]: '#d2d2d2',
    [MasterFinancialStatus.DECLINED]: '#e53935',
    [MasterFinancialStatus.INVOICED_AS_PART_OF_SET]: '#efa22d',
    [MasterFinancialStatus.INVOICED_TO_INSURER]: '#036eb0',
    [MasterFinancialStatus.INVOICED_TO_PATIENT]: '#61a058',
    [MasterFinancialStatus.NON_BILLABLE]: '#efa22d',
    [MasterFinancialStatus.NOT_COMPLETE]: '#efa22d',
    [MasterFinancialStatus.NOT_TO_BE_INVOICED]: '#efa22d',
    [MasterFinancialStatus.PARTIALLY_DECLINED]: '#e53935',
    [MasterFinancialStatus.PROCESSED]: '#61a058',
    [MasterFinancialStatus.TO_BE_CREDITED_TO_INSURER]: '#d2d2d2',
    [MasterFinancialStatus.TO_BE_INVOICED]: '#d2d2d2',
    [MasterFinancialStatus.TO_BE_INVOICED_TO_INSURER]: '#d2d2d2',
    [MasterFinancialStatus.TO_BE_INVOICED_TO_PATIENT]: '#d2d2d2',
};
