<template>
    <frm-input-smart-element-select
        v-model="selected"
        :disabled="disabled"
        :groups="groups"
        :label-all="$t('calendar.filter-employees-all')"
        :label-items="$tc('calendar.filter-employees', 2)"
        :multiple="multiple"
        :options="options"
        select-class="el-select--dark"
    />
</template>

<script>
    import FilterSelectItem from '../../../mixins/Calendar/FilterSelectItem';
    import sortArrayAlphabeticallyByProperty from '../../../services/sortArrayAlphabeticallyByProperty';

    export default {
        mixins: [FilterSelectItem],

        computed: {
            options() {
                return sortArrayAlphabeticallyByProperty(
                    this.$store.getters['calendar/employees'].map(employee => {
                        return {
                            id: employee.id,
                            label: employee.name.fullname,
                            info: employee.employee_groups ? employee.employee_groups.map(group => group.description).join(', ') : '',
                        };
                    }),
                    'label'
                );
            },

            groups() {
                const groups = {};

                this.$store.getters['calendar/employees'].forEach(employee => {
                    if (employee.employee_groups) {
                        employee.employee_groups.forEach(group => {
                            if (group.id in groups) {
                                return;
                            }

                            groups[group.id] = {
                                id: group.id,
                                label: group.description,
                                options: group.employees.map(employee => employee.id),
                                info: `${group.employees.length} ${this.$tc('calendar.filter-employees', group.employees.length).toLowerCase()}`,
                            };
                        });
                    }
                });

                return sortArrayAlphabeticallyByProperty(Object.values(groups), 'label');
            },
        },
    };
</script>
