<template>
    <frm-panel :title="$t('calendar.sidebar.invoicing-status')">
        <ul class="calendar-legend">
            <li v-for="(item, index) in items" :key="index" class="calendar-legend__item calendar-legend__item--bullet" :style="{ '--item-color': item.color }">
                {{ item.label }}
            </li>
        </ul>
    </frm-panel>
</template>

<script>
    import * as MasterFinancialStatus from '../../../enums/MasterFinancialStatus';
    import MasterFinancialStatusColors from '../../../enums/MasterFinancialStatusColors';

    export default {
        computed: {
            items() {
                return [
                    {
                        label: this.$t('financial.enums.master_financial_status.to_be_invoiced'),
                        color: MasterFinancialStatusColors[MasterFinancialStatus.TO_BE_INVOICED],
                    },
                    {
                        label: this.$t('financial.enums.master_financial_status.invoiced_to_insurer'),
                        color: MasterFinancialStatusColors[MasterFinancialStatus.INVOICED_TO_INSURER],
                    },
                    {
                        label: `${this.$t('financial.enums.master_financial_status.invoiced_to_patient')} / ${this.$t('financial.enums.master_financial_status.approved')}`,
                        color: MasterFinancialStatusColors[MasterFinancialStatus.INVOICED_TO_PATIENT],
                    },
                    {
                        label: this.$t('financial.enums.master_financial_status.approved_credit_to_insurer--long'),
                        color: MasterFinancialStatusColors[MasterFinancialStatus.APPROVED_CREDIT_TO_INSURER],
                    },
                    {
                        label: `${this.$t('financial.enums.master_financial_status.declined')} / ${this.$t('financial.enums.master_financial_status.partially_declined')}`,
                        color: MasterFinancialStatusColors[MasterFinancialStatus.DECLINED],
                    },
                    {
                        label: this.$t('financial.enums.master_financial_status.not_to_be_invoiced'),
                        color: MasterFinancialStatusColors[MasterFinancialStatus.NOT_TO_BE_INVOICED],
                    },
                ];
            },
        },
    };
</script>
