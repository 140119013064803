// @vue/component
export default {
    props: {
        value: {
            type: Array,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },

        options() {
            return [];
        },

        groups() {
            return [];
        },
    },
};
