<template>
    <div>
        <div class="info-list">
            <h3 v-if="participation.indication" class="info-list__header">
                {{ participation.indication }} ({{ $ds(participation.date_of_indication, 'date') }})
            </h3>
            <dl class="info-list__overview" :class="{'info-list__overview--margin': diagnosisCodeEnabled}">
                <dt v-if="participation.insurer_name" class="info-list__label" v-text="$t('calendar.appointment.insurer_name')" />
                <dd v-if="participation.insurer_name" class="info-list__value" v-text="participation.insurer_name" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.cov')" />
                <dd class="info-list__value" v-text="formatCovCheck(participation)" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.general-practitioner')" />
                <dd class="info-list__value" v-text="participation.general_practitioner" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.general-practitioner-phone')" />
                <dd class="info-list__value" v-text="participation.general_practitioner_phone_number" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.referrer')" />
                <dd class="info-list__value" v-html="participation.referrer" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.therapist')" />
                <dd class="info-list__value" v-text="participation.therapist" />
            </dl>
            <dl v-if="diagnosisCodeEnabled" class="info-list__overview">
                <dt class="info-list__label" v-text="$t('calendar.appointment.diagnosis-code')" />
                <dd class="info-list__value" v-text="participation.diagnosis_code" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.chronic')" />
                <dd class="info-list__value" v-text="participation.chronic" />
            </dl>
            <div class="info-list-separator" />
            <h3 class="info-list__header">
                {{ $t('calendar.appointment.treatment') }}
            </h3>
            <dl class="info-list__overview">
                <dt class="info-list__label" v-text="$t('calendar.appointment.total-amount')" />
                <dd class="info-list__value" v-text="participation.total_amount" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.performance-code')" />
                <dd class="info-list__value" v-text="participation.performance_code" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.indication-code')" />
                <dd class="info-list__value" v-text="participation.indication_code" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.paid-by')" />
                <dd class="info-list__value" v-text="participation.paid_by" />
                <dt class="info-list__label" v-text="$t('calendar.appointment.appointment_number')" />
                <dd class="info-list__value">
                    {{ participation.current_appointment_number }}/{{ participation.total_appointment_count }}
                </dd>
            </dl>
        </div>
        <div class="info-list-separator" />
        <template v-if="participation._paid_by === 'PaidByPatient' && participation.private_invoicing_details">
            <div class="info-list info-list--highlight">
                <h3 class="info-list__header">
                    {{ participation.paid_by }}
                </h3>
                <dl class="info-list__overview">
                    <dt class="info-list__label info-list__label--wide"
                        v-text="$t('calendar.appointment.invoiced-to-patient')"
                    />
                    <dd class="info-list__value info-list__value--wide info-list__value--right"
                        v-text="participation.private_invoicing_details.invoiced_to_patient"
                    />
                    <dt class="info-list__label info-list__label--wide"
                        v-text="$t('calendar.appointment.to-be-invoiced-to-patient')"
                    />
                    <dd class="info-list__value info-list__value--wide info-list__value--right"
                        v-text="participation.private_invoicing_details.to_be_invoiced_to_patient"
                    />
                </dl>
            </div>
            <div class="info-list-separator" />
        </template>
        <div v-if="participation.insurance_details && participation.insurance_details.length"
             data-testid="insurance-details"
        >
            <template v-for="(insurance_detail, index) in participation.insurance_details">
                <div :key="index"
                     class="info-list"
                     :class="{'info-list--highlight': insurance_detail.is_active, 'info-list--tone': !insurance_detail.is_active}"
                >
                    <h3 class="info-list__header">
                        {{ insurance_detail.description }}
                    </h3>
                    <dl class="info-list__overview">
                        <dt class="info-list__label info-list__label--wide"
                            v-text="$t('calendar.appointment.reimbursement-total')"
                        />
                        <dd class="info-list__value info-list__value--wide info-list__value--right"
                            v-text="insurance_detail.reimbursement_total"
                        />
                        <dt class="info-list__label info-list__label--wide"
                            v-text="$t('calendar.appointment.reimbursement-used-elsewhere')"
                        />
                        <dd class="info-list__value info-list__value--wide info-list__value--right"
                            v-text="insurance_detail.reimbursement_used_elsewhere"
                        />
                        <dt class="info-list__label info-list__label--wide"
                            v-text="$t('calendar.appointment.reimbursement-up-to-and-including-this')"
                        />
                        <dd class="info-list__value info-list__value--wide info-list__value--right"
                            v-text="insurance_detail.reimbursement_up_to_and_including_today"
                        />
                        <dt class="info-list__label info-list__label--wide"
                            v-text="$t('calendar.appointment.reimbursement-planned')"
                        />
                        <dd class="info-list__value info-list__value--wide info-list__value--right"
                            v-text="insurance_detail.reimbursement_planned"
                        />
                        <dt class="info-list__label info-list__label--wide"
                            v-text="$t('calendar.appointment.reimbursement-left')"
                        />
                        <dd class="info-list__value info-list__value--wide info-list__value--right"
                            v-text="insurance_detail.reimbursement_left"
                        />
                    </dl>
                </div>
                <div :key="index + '-separator'" class="info-list-separator" />
            </template>
        </div>
    </div>
</template>

<script>
export default {

    methods: {
        formatCovCheck(participation) {
            return participation.last_cov_check_date
                ? `${this.$t('base.yes')} (${this.$ds(participation.last_cov_check_date, 'date')})`
                : this.$t('base.no');
        },
    },

    props: {
        participation: {
            type: Object,
            required: true,
        },

        diagnosisCodeEnabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
