export const OPEN = 'open';

export const OPEN_GROUP = 'open_group';

export const DELETE = 'delete';

export const DELETE_RECURRENCES = 'delete-recurrences';

export const CUT = 'cut';

export const COPY = 'copy';

export const PASTE = 'paste';

export const OPEN_FYSIOROADMAP = 'open-fysioroadmap';

export const OPEN_PAYMENT_STATUS = 'open-payment-status';

export const EDIT_CONTACT_MOMENT = 'edit-contact-moment';

export const EDIT_PATIENT = 'edit-patient';

export const EDIT_PATIENT_POLICY = 'edit-patient-policy';

export const SHOW_PATIENT_POLICY = 'show-patient-policy';

export const EDIT_PATIENT_SUBSCRIPTION = 'edit-patient-subscription';

export const EDIT_INDICATION_HISTORY = 'edit-indication-history';

export const APPOINTMENT = 'appointment';

export const CREATE_MESSAGE = 'create-message';

export const CREATE_ACTION = 'create-action';

export const NOTE = 'note';

export const EDIT_NOTE = 'edit-note';

export const DELETE_NOTE = 'delete-note';

export const UNFULFILLED = 'unfulfilled';

export const COV = 'cov';

export const INVOICE = 'invoice';
