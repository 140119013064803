<template>
    <button class="appointment-button" type="button" @click="createAppointment()">
        <frm-icon name="plus" class="appointment-button__icon" />
    </button>
</template>

<script>
    import moment from 'moment';
    import ModalManager from '../../services/modalManager';
    import dateFormatter from '../../i18n/dateFormatter';

    export default {
        methods: {
            createAppointment() {
                const now = moment();
                const start = now.clone()
                    .year(this.$store.getters['calendar/date'].year())
                    .month(this.$store.getters['calendar/date'].month())
                    .date(this.$store.getters['calendar/date'].date())
                    .add((60 - now.minutes()) % 15, 'minutes');
                const end = start.clone()
                    .add(30, 'minutes');

                ModalManager.openModal(`/calendar/appointment/create?start=${dateFormatter(start)}&end=${dateFormatter(end)}&${this.$store.state.calendar.filter.mode}=${this.$store.state.calendar.filter.value}`);
            },
        },
    };
</script>

<style lang="scss">
    .appointment-button {
        @include button-base;

        border-radius: 50%;
        bottom: rhythm(1.5);
        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
        height: rhythm(5);
        padding: 0;
        position: absolute;
        right: rhythm(1.5);
        width: rhythm(5);
        z-index: 1;

        @include breakpoint($show-full-calendar) {
            display: none;
        }
    }

    .appointment-button__icon {
        display: block;
        fill: $button__text-color;
        flex: 0 0 auto;
        height: rhythm(2.5);
        width: rhythm(2.5);
    }
</style>
