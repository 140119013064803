// @vue/component

import * as CalendarItemViewType from '../../enums/CalendarItemViewType';

export default {
    methods: {
        eventIsAppointment(event) {
            return event.extendedProps.eventType === CalendarItemViewType.APPOINTMENT;
        },
        eventIsPlaceholder(event) {
            return [CalendarItemViewType.EMPLOYEE_SCHEDULE_PLACEHOLDER, CalendarItemViewType.LOCATION_DEVICE_SCHEDULE_PLACEHOLDER, CalendarItemViewType.LOCATION_ROOM_SCHEDULE_PLACEHOLDER].includes(event.extendedProps.eventType);
        },
        eventIsNote(event) {
            return [CalendarItemViewType.NOTE].includes(event.extendedProps.eventType);
        },
        eventIsScheduleBackground(event) {
            return [CalendarItemViewType.EMPLOYEE_SCHEDULE_BACKGROUND, CalendarItemViewType.LOCATION_DEVICE_SCHEDULE_BACKGROUND, CalendarItemViewType.LOCATION_ROOM_SCHEDULE_BACKGROUND].includes(event.extendedProps.eventType);
        },
    },
};
