<template>
    <section class="modal-component">
        <header class="modal-component__header">
            <h1 class="modal-component__title">
                {{ $t('calendar.section-names.appointment') }}
            </h1>
        </header>

        <div class="modal-component__content">
            <frm-calendar-appointment-info :anonymous="anonymous" :event="event" :diagnosis-code-enabled="diagnosisCodeEnabled" />
        </div>
    </section>
</template>

<script>
    import CalendarAppointmentInfo from '../Calendar/AppointmentInfo';

    export default {
        components: {
            'frm-calendar-appointment-info': CalendarAppointmentInfo,
        },

        props: {
            event: {
                type: Object,
                required: true,
            },

            anonymous: {
                type: Boolean,
                default: false,
            },

            diagnosisCodeEnabled: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal-component__content {
        height: calc(95vh - #{rhythm(7)});
        overflow: auto;
    }
</style>
