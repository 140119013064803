import Vue from 'vue';

export const setEvents = (state, events) => {
    state.events = events;
};

export const setSchedules = (state, schedules) => {
    state.schedules = schedules;
};

export const setEmployees = (state, employees) => {
    state.employees = employees;
};

export const setRooms = (state, rooms) => {
    state.rooms = rooms;
};

export const setDevices = (state, devices) => {
    state.devices = devices;
};

export const setLocations = (state, locations) => {
    state.locations = locations;
};

export const setActiveLocations = (state, locations) => {
    state.activeLocations = locations;
};

export const setDate = (state, date) => {
    state.date = date.toJSON();
};

export const setMode = (state, mode) => {
    state.mode = mode;
};

export const setFilter = (state, { mode, value }) => {
    state.filter.mode = mode;
    state.filter.value = value;
};

export const setFilterMode = (state, mode) => {
    state.filter.mode = mode;
    state.filter.value = [];
};

export const setFilterValue = (state, value) => {
    state.filter.value = value;
};

export const setSearch = (state, value) => {
    state.search = value;
};

export const updateEvent = (state, event) => {
    Vue.set(state.events, event.hash, { ...state.events[event.hash], ...event });
};

export const setAnonymous = (state, value) => {
    state.anonymous = value;
};

export const setLoading = (state, { type, loading }) => {
    state.loading[type] = loading;
};

export const setActiveMutation = (state, activeMutation) => {
    state.activeMutation = activeMutation;
};

export const setOpenedEvent = (state, event) => {
    state.openedEvent = event;
};
