<template>
    <frm-input-smart-element-select
        v-model="selected"
        :label-all="$t('calendar.filter-rooms-all')"
        :label-items="$tc('calendar.filter-rooms', 2)"
        :multiple="multiple"
        :options="options"
        select-class="el-select--dark"
    />
</template>

<script>
    import FilterSelectItem from '../../../mixins/Calendar/FilterSelectItem';
    import sortArrayAlphabeticallyByProperty from '../../../services/sortArrayAlphabeticallyByProperty';

    export default {
        mixins: [FilterSelectItem],

        computed: {
            options() {
                const options = {};

                this.$store.getters['calendar/rooms'].forEach(room => {
                    if (!(room.location.id in options)) {
                        options[room.location.id] = {
                            label: room.location.description,
                            options: [],
                        };
                    }

                    options[room.location.id].options.push({
                        id: room.id,
                        label: room.description,
                    });
                });

                return sortArrayAlphabeticallyByProperty(
                    Object.values(options).map(group => {
                        return { ...group, options: sortArrayAlphabeticallyByProperty(group.options, 'label') };
                    }),
                    'label'
                );
            },
        },
    };
</script>
